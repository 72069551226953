import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Fares from './components/pages/Fares';
import Airport from './components/pages/Airport';
import Outstation from './components/pages/Outstation';



function App() {
  return (
   <>
   <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home/>} />
        <Route path='/fares' exact element={<Fares/>} />
        <Route path='/airport' exact element={<Airport/>} />
        <Route path='/outstation' exact element={<Outstation/>} />
      </Routes>
   </Router>
   </>
  );
}

export default App;
