import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, calories, fat, carbs) {
  return { name, calories, fat, carbs };
}

const rows = [
createData('Charges & Kms' ,' Rs.700/-@40 Kms ' ,'Rs.800/-@40 Kms ' ,'Rs.1100/-@40 Kms ' )
];

export default function Fares() {
  return (
    <Box sx={{mx:3,my:5 ,color:'#f4f424'}} >
    <Typography variant='h5'sx={{mb:2.5,color:'orangered'}} >City to Airport Package</Typography>
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow  sx={{backgroundColor:'orangered' ,color:'#FFF !important'} }>
            <TableCell>DURATION </TableCell>
            <TableCell align="right">A/C INDICA</TableCell>
            <TableCell align="right"> A/C VERITO/INDIGO</TableCell>
            <TableCell align="right">A/C INNOVA/XYLO</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow  sx={{backgroundColor:'orangered' ,color:'#FFF !important'} }>
           
            <TableCell >Extra Cost Per Km  </TableCell>
            
            <TableCell align="right">Rs. 10/Km</TableCell>
            <TableCell align="right">Rs. 12/Km</TableCell>
            <TableCell align="right">Rs. 14/Km</TableCell>
          </TableRow>
        </TableHead>
        
      </Table>
    </TableContainer>
     <Typography variant='h5'sx={{mt:4.5,color:'orangered'}} >Airport to City Package</Typography>
    <TableContainer component={Paper} sx={{mt:2.5}} >
      <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow  sx={{backgroundColor:'orangered' ,color:'#FFF !important'} }>
            <TableCell>DURATION </TableCell>
            <TableCell align="right">A/C INDICA</TableCell>
            <TableCell align="right"> A/C VERITO/INDIGO</TableCell>
            <TableCell align="right">A/C INNOVA/XYLO</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Charges & Kms
              </TableCell>
              <TableCell align="right">Rs.600/-@40 Kms</TableCell>
              <TableCell align="right">Rs.650/-@40 Kms</TableCell>
              <TableCell align="right">Rs.950/-@40 Kms</TableCell>
            </TableRow>
        </TableBody>
        <TableHead>
          <TableRow  sx={{backgroundColor:'orangered' ,color:'#FFF !important'} }>
           
            <TableCell >Extra Cost Per Km  </TableCell>
            
            <TableCell align="right">Rs. 10/Km</TableCell>
            <TableCell align="right">Rs. 12/Km</TableCell>
            <TableCell align="right">Rs. 14/Km</TableCell>
          </TableRow>
        </TableHead>
        
      </Table>
    </TableContainer>
     <Typography variant='h5'sx={{mt:4.5,color:'orangered'}} >Airport up & down package</Typography>
    <TableContainer component={Paper} sx={{mt:2.5}} >
      <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow  sx={{backgroundColor:'orangered' ,color:'#FFF !important'} }>
            <TableCell>DURATION </TableCell>
            <TableCell align="right">A/C INDICA</TableCell>
            <TableCell align="right"> A/C VERITO/INDIGO</TableCell>
            <TableCell align="right">A/C INNOVA/XYLO</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Charges & Kms
              </TableCell>
              <TableCell align="right">Rs.1300/-@70 Kms</TableCell>
              <TableCell align="right">Rs.1500/-@70 Kms</TableCell>
              <TableCell align="right">Rs.1900/-@70 Kms</TableCell>
            </TableRow>
        </TableBody>
        <TableHead>
          <TableRow  sx={{backgroundColor:'orangered' ,color:'#FFF !important'} }>
           
            <TableCell>Extra Cost Per Km  </TableCell>
            
            <TableCell align="right">Rs. 10/Km</TableCell>
            <TableCell align="right">Rs. 12/Km</TableCell>
            <TableCell align="right">Rs. 14/Km</TableCell>
          </TableRow>
        </TableHead>
        
      </Table>
    </TableContainer>
    </Box>
  );
}