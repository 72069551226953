import React from 'react';
import { Link } from 'react-router-dom';
import { FaPhoneAlt  } from "react-icons/fa";
import Typography from '@mui/material/Typography';
import './Cards.css';

function Card(props) {
    return (
        <div className ='card'>
        {props.icon}
        <Typography variant ='h6' mt={1.5} mb={2.5}>{props.data.title}</Typography >
        <Typography variant ='body1'>{props.data.value} </Typography> 
        { props.val && <Typography variant ='body1' mt={1.5}><FaPhoneAlt fontSize='16px'  /> &nbsp;+91 8686568651</Typography>    }

        </div>
    )
}

export default Card;
